<template>
  <main class="section">
    <div class="container">
      <div class="columns has-text-centered">
        <div class="column is-half is-offset-one-quarter">
          <div class="card">
            <header class="card-header has-background-primary">
              <div class="card-header-title has-text-light is-uppercase is-size-5">{{ header }}</div>
            </header>
            <div class="card-content events-card">
              <div class="has-text-centered">
                <p>{{ message }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  props: {
    header: String,
    message: String
  }
};
</script>